module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","start_url":"/","background_color":"#ef0012","theme_color":"#ef0012","display":"starndalone","icon":"src/images/icon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-158859691-1","head":false,"pageTransitionDelay":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
