// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-news-post-js": () => import("./../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-pages-basic-information-index-en-js": () => import("./../src/pages/basic-information/index.en.js" /* webpackChunkName: "component---src-pages-basic-information-index-en-js" */),
  "component---src-pages-basic-information-index-ja-js": () => import("./../src/pages/basic-information/index.ja.js" /* webpackChunkName: "component---src-pages-basic-information-index-ja-js" */),
  "component---src-pages-basic-information-index-js": () => import("./../src/pages/basic-information/index.js" /* webpackChunkName: "component---src-pages-basic-information-index-js" */),
  "component---src-pages-basic-information-kaigo-index-en-js": () => import("./../src/pages/basic-information/kaigo/index.en.js" /* webpackChunkName: "component---src-pages-basic-information-kaigo-index-en-js" */),
  "component---src-pages-basic-information-kaigo-index-ja-js": () => import("./../src/pages/basic-information/kaigo/index.ja.js" /* webpackChunkName: "component---src-pages-basic-information-kaigo-index-ja-js" */),
  "component---src-pages-basic-information-kaigo-index-js": () => import("./../src/pages/basic-information/kaigo/index.js" /* webpackChunkName: "component---src-pages-basic-information-kaigo-index-js" */),
  "component---src-pages-faq-index-en-js": () => import("./../src/pages/faq/index.en.js" /* webpackChunkName: "component---src-pages-faq-index-en-js" */),
  "component---src-pages-faq-index-ja-js": () => import("./../src/pages/faq/index.ja.js" /* webpackChunkName: "component---src-pages-faq-index-ja-js" */),
  "component---src-pages-faq-index-js": () => import("./../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-1-index-en-js": () => import("./../src/pages/interview/1/index.en.js" /* webpackChunkName: "component---src-pages-interview-1-index-en-js" */),
  "component---src-pages-interview-1-index-ja-js": () => import("./../src/pages/interview/1/index.ja.js" /* webpackChunkName: "component---src-pages-interview-1-index-ja-js" */),
  "component---src-pages-interview-1-index-js": () => import("./../src/pages/interview/1/index.js" /* webpackChunkName: "component---src-pages-interview-1-index-js" */),
  "component---src-pages-interview-2-index-en-js": () => import("./../src/pages/interview/2/index.en.js" /* webpackChunkName: "component---src-pages-interview-2-index-en-js" */),
  "component---src-pages-interview-2-index-ja-js": () => import("./../src/pages/interview/2/index.ja.js" /* webpackChunkName: "component---src-pages-interview-2-index-ja-js" */),
  "component---src-pages-interview-2-index-js": () => import("./../src/pages/interview/2/index.js" /* webpackChunkName: "component---src-pages-interview-2-index-js" */),
  "component---src-pages-interview-3-index-en-js": () => import("./../src/pages/interview/3/index.en.js" /* webpackChunkName: "component---src-pages-interview-3-index-en-js" */),
  "component---src-pages-interview-3-index-ja-js": () => import("./../src/pages/interview/3/index.ja.js" /* webpackChunkName: "component---src-pages-interview-3-index-ja-js" */),
  "component---src-pages-interview-3-index-js": () => import("./../src/pages/interview/3/index.js" /* webpackChunkName: "component---src-pages-interview-3-index-js" */),
  "component---src-pages-interview-index-en-js": () => import("./../src/pages/interview/index.en.js" /* webpackChunkName: "component---src-pages-interview-index-en-js" */),
  "component---src-pages-interview-index-ja-js": () => import("./../src/pages/interview/index.ja.js" /* webpackChunkName: "component---src-pages-interview-index-ja-js" */),
  "component---src-pages-interview-index-js": () => import("./../src/pages/interview/index.js" /* webpackChunkName: "component---src-pages-interview-index-js" */),
  "component---src-pages-news-index-en-js": () => import("./../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-ja-js": () => import("./../src/pages/news/index.ja.js" /* webpackChunkName: "component---src-pages-news-index-ja-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-what-is-kaigo-index-en-js": () => import("./../src/pages/what-is-kaigo/index.en.js" /* webpackChunkName: "component---src-pages-what-is-kaigo-index-en-js" */),
  "component---src-pages-what-is-kaigo-index-ja-js": () => import("./../src/pages/what-is-kaigo/index.ja.js" /* webpackChunkName: "component---src-pages-what-is-kaigo-index-ja-js" */),
  "component---src-pages-what-is-kaigo-index-js": () => import("./../src/pages/what-is-kaigo/index.js" /* webpackChunkName: "component---src-pages-what-is-kaigo-index-js" */)
}

